const SECTIONS = [
  `IBC Blow Moulding Machine`,
  `L Ring Drum Blow Moulding Machine`,
  `Water Tank Blow Moulding Machine`,
  `Flat & Table Blow Moulding Machine`,
  `500 Liter Blow moulding machine`,
  `200 Liter Blow moulding machine`,
  `160 Liter Blow moulding machine`,
  `120 Liter blow moulding machine`,
  `60 Liter blow moulding machine`,
  `30 Liter blow moulding machine`,
  `20 Liter blow moulding machine`,
  `10 Liter blow moulding machine`,
  `5 Liter blow moulding machine`,
  // `Moulds`,
  // `Auxiliary`,
  // `Induction heaters`,
];

export const SECTION_MAP = {
  'IBC Blow Moulding Machine': [
    'afb12fd3-9761-4b1f-b51b-c7510c314e32',
    '396652e7-0a5d-4872-9b7d-50f7044a7cd4',
  ],
  'L Ring Drum Blow Moulding Machine': [
    '0b84b0c3-8d4b-4586-8f7d-2d8952e588e9',
    '862fa123-51a1-4f1f-ac30-6cb5cf63df48',
  ],
  'Water Tank Blow Moulding Machine': [
    '5c580e1e-bc1f-4bd1-8377-31036993a2c3',
    '53939041-b99d-4d0f-8e43-afed8a99413d',
    '7340ca51-f2ee-4793-a719-8d13cbf6363b',
    '2034bbd6-114e-41f3-b7d6-b69c17807638',
    'f4591297-7bae-4fcf-8657-b8407b14532a',
    '87efd352-a238-4a31-a444-c67552ee720d',
    '801af499-2dba-4abd-af18-db431e0a12a5',
    '069192f2-ac22-4523-9e8e-4a483ba63e70',
    '8074f580-812e-4328-8870-9b63462fa095',
    'e388baba-f7c8-4549-b70a-56926b2ff299',
    '48fa7199-39ac-41eb-9263-561e3fb401d0',
    '8ab3a705-56e4-415d-ae5a-bc8862604d29',
    'b105d7d3-c4b3-4826-a923-5ac6a0ae3d7c',
    'b4a6a925-6f1d-4c43-91ac-054bf3a73658',
    'abef197b-fcb8-4451-a4e7-f8a0866a935d',
  ],
  'Flat & Table Blow Moulding Machine': [
    '7549ee91-0db3-413a-9305-eddc3e8390cf',
    '00222746-0565-4a8b-8d09-9781c0b958b7',
    'b4310079-f9fa-476f-8c29-1c90a42d4790',
  ],
  '500 Liter Blow moulding machine': [
    'e358fe93-ad51-4c44-b8a2-296e7547ef68',
    'ccc8296d-e082-4b48-bf88-3634c5c186bf',
  ],
  '200 Liter Blow moulding machine': [
    'fe63c035-61ff-45a0-9148-018556ab397d',
    '19ba3268-1301-4bae-80e7-ff92ad95c6f1',
  ],
  '160 Liter Blow moulding machine': ['c631ec3b-bf83-4953-a42d-e4b626364398'],
  '120 Liter blow moulding machine': ['426d0c0b-cbc1-4387-b6fc-841a2eb2b3fc'],
  '60 Liter blow moulding machine': [
    'a686a341-def4-490d-a3dd-3fc0682ec351',
    '3c7aa552-0c50-4ac9-bf6a-94b8b4f1f322',
  ],
  '30 Liter blow moulding machine': [
    '62ff95c4-5287-43e6-a776-1ed455784dca',
    '0d5ded99-b683-4895-89a1-408bfdba5009',
  ],
  '20 Liter blow moulding machine': [
    'd8db9238-dc33-4a08-89b2-e58971db19a1',
    'f2e3e9cc-8e8e-498c-af5f-3d7e684dad38',
  ],
  '10 Liter blow moulding machine': [
    '2e9006f1-c0ea-41b8-9a74-bd3ac96b536f',
    '64134446-b93e-470f-a8aa-a9ea78b273d4',
    '49e303bc-ee47-4a42-af9e-41393add31de',
  ],
  '5 Liter blow moulding machine': [
    'eee2c750-a2fe-4912-a6b8-6bec405a0624',
    '477e3130-a58a-4fab-8208-376ee6b1f2dc',
  ],
  // Moulds: ['7843f82b-5d03-461c-a671-c06bb73b23be'],
  // Auxiliary: ['0a917d60-e705-4126-94a5-ebe6a7e0fd02'],
  // 'Induction heaters': [],
  'Unassigned Section': ['N/A'],
};

export default SECTIONS;
