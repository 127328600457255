import "es6-shim";
import "./App.css";
import React from "react";
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/_Navbar';
import Content from './components/Content';
import ContentClassic from './components/ContentClassic';

import AuthForm from './components/AuthForm';
import CarouselHOC from './components/CarouselHOC';
import db from './db.json';
export const labels = [
  { label: 'Machinery', _path: '/blowmouldingmachine', path: '/machinery' },
  { label: 'Mould & Auxiliary', _path: '/mould&auxiliary', path: '/mould&auxiliary' },
  { label: 'Service & Tech', _path: '/technology', path: '/service&tech' },
  { label: 'About Us', _path: '/aboutus', path: '/aboutus' },
  { label: 'Contact Us', _path: '/contactus', path: '/contactus' },
  { label: 'Download', _path: '/download', path: '/download' },
];

export default function App({ history }) {
  const [isAllowBrowsing, setBrowsingPermission] = React.useState(true); //temp
  const [userPrivilege, setUserPrivilege] = React.useState('visitor');

  // React.useEffect(() => {
  //   if (!isAllowBrowsing) {
  //     fetch('https://geolocation-db.com/json/')
  //       .then(response => {
  //         return response.json();
  //       }, 'jsonp')
  //       .then(res => {
  //         if (res.country_name === 'China') {
  //         } else {
  //           setBrowsingPermission(true);
  //         }
  //       })
  //       .catch(err => {
  //         if (process.env.NODE_ENV !== 'production') {
  //           console.log(err);
  //         }
  //         return null;
  //       });
  //   }
  //   // updateToLatestVersion();
  // }, [isAllowBrowsing]);
  const handleSubmission = React.useCallback(async values => {
    setBrowsingPermission(true);
  }, []);
  const setAdminPrivilege = () => {
    setUserPrivilege('admin');
  };
  const [state, setState] = React.useState({});
  const setSortedContent = React.useCallback((data, section) => {
    setState(state => ({
      ...state,
      [section]: data
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map(c => ({
          ...c,
          content: typeof c.content === 'string' ? JSON.parse(c.content) : c.content,
        })),
    }));
  }, []);
  const retrieveContent = React.useCallback(
    async section => {
      const contentData = db
        .filter(it => it.section.match(new RegExp(section, 'gi')))
        .map(it => ({ ...it, content: JSON.parse(it.content) }));
      setSortedContent(contentData, section);
    },
    [setSortedContent],
  );
  React.useEffect(() => {
    if (!isAllowBrowsing && process.env.NODE_ENV === 'production') return;

    labels.forEach(l => retrieveContent(l._path.slice(1)));
  }, [isAllowBrowsing, retrieveContent]);

  if (!isAllowBrowsing && process.env.NODE_ENV === 'production') {
    return <AuthForm onSubmit={handleSubmission} disableBackdropClick open />;
  }

  return (
    <Router history={history}>
      <Switch>
        {labels.map(l => {
          return (
            <Route
              key={l.label}
              path={l.path}
              component={() => (
                <Navbar setAdminPrivilege={setAdminPrivilege} userPrivilege={userPrivilege}>
                  {l._path.slice(1) === 'blowmouldingmachine' ? (
                    <Content
                      section={l._path.slice(1)}
                      className="body"
                      userPrivilege={userPrivilege}
                      sectionContent={state[l._path.slice(1)]}
                      setContent={(data, section) => {
                        setSortedContent(data, section);
                      }}
                      retrieveContent={retrieveContent}
                    />
                  ) : (
                    <ContentClassic
                      section={l._path.slice(1)}
                      className="body"
                      userPrivilege={userPrivilege}
                      sectionContent={state[l._path.slice(1)]}
                      setContent={(data, section) => {
                        setSortedContent(data, section);
                      }}
                      retrieveContent={retrieveContent}
                      download={l._path.match(/download/gi)}
                    />
                  )}
                </Navbar>
              )}
            ></Route>
          );
        })}
        <Route path="/">
          <Navbar setAdminPrivilege={setAdminPrivilege} userPrivilege={userPrivilege}>
            <Content
              section={labels[0]._path.slice(1)}
              className="body"
              userPrivilege={userPrivilege}
              sectionContent={state[labels[0]._path.slice(1)]}
              setContent={(data, section) => {
                setSortedContent(data, section);
              }}
            />
          </Navbar>
        </Route>
        <Route path="*">
          <Navbar setAdminPrivilege={setAdminPrivilege} userPrivilege={userPrivilege}>
            Content Not Found
          </Navbar>
        </Route>
      </Switch>{' '}
    </Router>
  );
}
