import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
const useSpinnerStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    zIndex: 999,
    position: "fixed",
    width: "100vw",
    opacity: ".6",
    height: `400px`,
    justifyContent: `center`,
    flexFlow: `column`,
    alignItems: `center`,
    top: "0",
  },
  fakeInput: {
    visibility: "hidden",
  },
}));

export function CircularIndeterminate() {
  const classes = useSpinnerStyles();

  return (
    <div className={classes.root}>
      <input autoFocus className={classes.fakeInput} />
      <CircularProgress />
    </div>
  );
}
