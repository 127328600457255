import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    margin: `auto`,
    cursor: 'pointer',
    height: '20%',
    width: '75%',
  },
  header: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  carouselWrapper: {
    display: `flex`,
    margin: `10px auto`,
    width: `max(90% , 300px)`,
  },
  icon: {
    '.MuiSvgIcon-root': {
      fontSize: `min(45px, 8rem)`,
    },
    '.MuiIconButton-root': {
      padding: '0 !important',
    },
  },
  thumbDesc: {
    wordBreak: 'break-word',
    textAlign: 'left',
    fontStyle: 'italic',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    fontSize: '1rem',
    '@media (max-width: 667px)': {
      fontSize: '.75rem !important',
      height: '20px',
      overflow: 'hidden',
    },
  },
  content_root: {
    // padding: theme.spacing(2),
    display: `flex`,
    flexFlow: `column wrap`,
    gridGap: theme.spacing(2),
  },
  subroot: {
    display: `flex`,
    flexFlow: `row wrap`,
  },
  listPaper: {
    cursor: `pointer`,
    height: `300px`,
    background: `white`,
    // boxShadow: `3px 3px 3px 3px #b7b7b7`,
    flex: `1 1 100%`,
    borderBottom: '1px dashed grey',
    // padding: `0.25rem 0`,
    '@media (max-width: 667px)': {
      height: `100px`,
      overflow: `hidden`,
    },
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  listContent: {
    paddingLeft: theme.spacing(4),
  },
  paragraph: {
    fontStyle: 'italic',
    lineHeight: '24px',
    wordBreak: 'break-all',
  },
  subpaper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textAlign: 'center',
  },
  cardImg: {
    height: '90%',
    width: '90%',
    margin: 'auto',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  displayedTitle: {
    textAlign: `center`,
    fontFamily: `fantasy`,
    color: 'red',
  },
  listContentPadding: {
    padding: '0px 12px',
    flexFlow: `row nowrap`,
    display: 'flex',
    gap: '.25rem',
  },
  inheritHeightContainer: {},
  home_root: {
    width: `100%`,
    margin: '0px',
  },
  sectionTitle: {
    fontFamily: `monospace`,
    color: '#4051b5',
    fontWeight: 'bold',
    textShadow: '2px 2px 2px #fff',
    borderRadius: `15px`,
    '@media (max-width: 667px)': {
      fontSize: '2rem',
      width: `100%`,
    },
  },
  title: {
    fontFamily: `monospace`,
    color: '#e88f0b',
    fontWeight: 'bold',
    textShadow: '2px 2px 2px #811007',
    borderRadius: `15px`,
    '@media (max-width: 667px)': {
      fontSize: '1rem',
      width: `100%`,
      height: 'auto',
    },
  },
  grid: {
    width: '90%',
    textAlign: 'center',
    alignItems: 'center',
    margin: 'auto',
    '@media (max-width: 667px)': {
      width: `100%`,
    },
  },
  placeholderDiv: {
    width: `90%`,
    margin: `10px auto`,
    fontSize: `1.5rem`,
    color: `#f44336`,
  },
  appBar: {
    position: 'relative',
  },
}));
export default useStyles;
