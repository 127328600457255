export const autoFindIMG = li => {
  if (!li?.coverImage) return '';
  return li.coverImage;
};

export const autoFindDesc = blocks => {
  const cutOffText = (blocks.find(b => b.text.trim().length > 0) || { text: '' }).text
    .split(' ')
    .slice(0, 50)
    .join(' ');
  return cutOffText.length > 0 ? `${cutOffText}...` : '';
};
