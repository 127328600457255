import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Button,
  MobileStepper,
  IconButton,
} from "@material-ui/core";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { autoFindIMG, autoFindDesc } from "../utils";
import useStyles from "../utils/styles";
import { PDFLoader } from './TextEditor';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function SwipeCarousel({ data, onView }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = item => {
    setActiveStep(item);
  };

  return (
    <>
      <div className={classes.root}>
        {data[activeStep] && (
          <>
            <Paper square className={classes.header}>
              <Typography variant="h6" className={classes.title}>
                {data[activeStep].title}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" className={classes.thumbDesc}>
                {autoFindDesc(data[activeStep].content.blocks)}
              </Typography>
            </Paper>
          </>
        )}
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          style={{
            height: '20vh',
          }}
          autoplay={false}
        >
          {data
            .sort((a, b) => (a.starred ? -1 : 1) - (b.starred ? -1 : 1))
            .map((item, index) => (
              <div key={item.title} onClick={() => onView(activeStep)}>
                {Math.abs(activeStep - index) <= 2 && activeStep === index ? (
                  item.pdfContent ? (
                    <PDFLoader file={item.pdfContent} />
                  ) : (
                    <img className={classes.img} src={autoFindIMG(item)} alt={item.title} />
                  )
                ) : null}
              </div>
            ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="progress"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </div>
      <hr />
    </>
  );
}
