import React from 'react';
import clsx from 'clsx';
import { Star, Add, KeyboardReturn, Edit, Delete } from '@material-ui/icons';
import { yellow, red, grey } from '@material-ui/core/colors';
import { Button, Grid, Paper, IconButton } from '@material-ui/core';
import TextEditor, { ProtoEditor, PDFLoader } from './TextEditor';
import { CircularIndeterminate } from './Spinner';
import 'draft-js/dist/Draft.css';

import { autoFindIMG, autoFindDesc } from '../utils';
import useStyles from '../utils/styles';
import { Typography, Dialog, AppBar, Toolbar, Slide, Box, CssBaseline } from '@mui/material';
import { Download } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const insertIntoArray = (arr, idx, value) => [...arr.slice(0, idx), value, ...arr.slice(idx + 1)];
const bufferStr = (file, regex) => file.replace(regex, '');
const MODE = { CREATE: 'create', EDIT: 'edit' };
const titleSX = {
  fontSize: { md: '3rem', xs: '1rem' },
  fontFamily: 'monospace',
  borderBottom: '1px solid #f2f2f2',
  display: `flex`,
  alignItems: `center`,
};
export default function Content({
  section,
  userPrivilege,
  sectionContent = [],
  setContent,
  retrieveContent,
  download,
}) {
  const classes = useStyles();
  const [mode, setMode] = React.useState('');
  const [spinner, setSpinner] = React.useState(false);
  const content = React.useMemo(
    () =>
      sectionContent
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map(c => ({
          ...c,
          content: typeof c.content === 'string' ? JSON.parse(c.content) : c.content,
        })),
    [sectionContent],
  );
  const [currentExpansion, setExpansion] = React.useState(-1);
  const [openEditor, setOpenEditor] = React.useState(false);

  React.useEffect(() => {
    setOpenEditor(false);
  }, [section]);

  const handleExpansion = idx => {
    setExpansion(idx === currentExpansion ? -1 : idx);
  };
  const handleStarring = async idx => {};
  const [currentEdit, setCE] = React.useState(null);
  const handleEdit = idx => {
    setCE(idx);
    setOpenEditor(true);
    setMode(MODE.EDIT);
  };
  const handleAddContent = () => {
    setCE(-1);
    setOpenEditor(oE => !oE);
    setMode(MODE.CREATE);
  };
  const handleDeletion = async idx => {};
  const saveEditorContent = React.useCallback(
    async ({ editorState, title, imageMap, coverImage, importFile, id }) => {},
    [],
  );
  const computedAllContent = React.useMemo(
    () => content.sort((a, b) => (a.starred ? 0 : 1) - (b.starred ? 0 : 1)),
    [content],
  );
  const currentContent = React.useMemo(() => {
    return content[currentExpansion] || {};
  }, [content, currentExpansion]);
  const currentEditContent = React.useMemo(() => {
    return content[currentEdit] || {};
  }, [content, currentEdit]);
  const isAdmin = React.useMemo(() => userPrivilege === 'admin', [userPrivilege]);
  const handleItemClose = () => {
    setExpansion(-1);
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={currentExpansion > -1}
        onClose={handleItemClose}
        TransitionComponent={Transition}
      >
        <Box>
          <CssBaseline />
          <AppBar className={classes.appBar} component={'nav'}>
            <Toolbar>
              <Typography variant="h3" sx={titleSX}>
                {currentContent.title}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleItemClose}
                aria-label="close"
                style={{ marginLeft: 'auto' }}
              >
                Return To Previous View
              </Button>
            </Toolbar>
          </AppBar>
          <Box component="main">
            <Toolbar />
            <Paper className={clsx(classes.paper, classes.subpaper)}>
              {currentContent.pdfContent && <PDFLoader file={currentContent.pdfContent} />}
              <ProtoEditor editorState={currentContent.content} readOnly />
            </Paper>
          </Box>
        </Box>
      </Dialog>
      <div className={classes.content_root} style={{ opacity: spinner ? '.6' : '1' }}>
        <div className={classes.subroot}>
          {!openEditor &&
            computedAllContent.map((li, idx) => (
              <ListCard
                key={`content-${idx}`}
                {...{
                  li,
                  idx,
                  handleExpansion,
                  classes,
                  currentExpansion,
                  handleStarring,
                  handleEdit,
                  isAdmin,
                  handleDeletion,
                  download,
                }}
              />
            ))}
          {openEditor && (
            <TextEditor
              saveEditorContent={saveEditorContent}
              currentContent={currentEditContent}
              cover={autoFindIMG(currentEditContent)}
              pdf={currentEditContent.pdfContent}
            />
          )}
        </div>
        {isAdmin && (
          <div
            style={{
              justifyContent: `flex-end`,
              display: `flex`,
              margin: `16px`,
            }}
          >
            <Button variant="contained" color="primary" onClick={handleAddContent}>
              {openEditor ? <KeyboardReturn /> : <Add />}
              <Typography variant="h6">
                {openEditor ? 'Return To List View' : 'Create New Title'}
              </Typography>
            </Button>
          </div>
        )}
      </div>
      {spinner && <CircularIndeterminate />}
    </React.Fragment>
  );
}

function ListCard({
  li,
  idx,
  currentExpansion,
  handleExpansion,
  handleStarring,
  handleEdit,
  classes,
  isAdmin,
  handleDeletion,
  download,
}) {
  if (currentExpansion !== idx && currentExpansion > -1) return null;
  return (
    <Grid
      container
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        handleExpansion(idx);
      }}
      className={classes.listPaper}
    >
      <Grid container item xs={2} className="cover">
        <div
          style={{
            backgroundImage: `url("${autoFindIMG(li)}")`,
          }}
          className={classes.cardImg}
        />
      </Grid>
      <Grid
        item
        xs={10}
        container
        direction="column"
        spacing={12}
        className={classes.inheritHeightContainer}
        style={{ gridGap: '10px', margin: 'auto' }}
      >
        <Grid container item className={classes.listContentPadding} justify="space-between">
          <Typography variant="h3" component="span" sx={titleSX} className="_title">
            {li.title}
          </Typography>
        </Grid>

        <Grid item className={classes.listContentPadding}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            className={classes.thumbDesc}
          >
            {autoFindDesc(li.content.blocks)}
          </Typography>
        </Grid>
        {download && (
          <Grid item container className={classes.listContentPadding}>
            <Button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                const downloadLink = document.createElement('a');
                downloadLink.target = '_blank';
                downloadLink.href = li.pdfContent;
                downloadLink.download = `${li.title}.pdf`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }}
              style={{ color: blue[400] }}
            >
              Download
              <Download />
            </Button>
          </Grid>
        )}
        {isAdmin && (
          <Grid item container className={classes.listContentPadding}>
            <Button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleEdit(idx);
              }}
              style={{ color: red[300], padding: '0px' }}
            >
              Edit
              <Edit />
            </Button>
            <IconButton
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                handleStarring(idx);
              }}
            >
              <Star style={{ color: li.starred ? yellow[300] : grey[300] }} />
            </IconButton>
            <IconButton
              onClick={async event => {
                event.preventDefault();
                event.stopPropagation();
                handleDeletion(idx);
              }}
            >
              <Delete style={{ color: red[500] }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
