import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Menu } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import AuthForm from './AuthForm';
import Logo from '../assets/logo.jpg';
import { Link as RouterLink } from 'react-router-dom';
import { labels } from '../App';
import { CircularIndeterminate } from './Spinner';
import { version } from '../../package.json';

const useStyles = makeStyles(theme => ({
  startButton: {
    marginRight: theme.spacing(10),
  },
  //   title: {
  //     flex: '1 0 auto',
  //   },
  endButton: {
    marginLeft: theme.spacing(10),
  },
  root: {
    display: `flex`,
    position: `relative`,
    alignItems: `center`,
    flexFlow: `row wrap`,
    justifyContent: `space-between`,
  },
}));
const drawerWidth = 240;

function DrawerAppBar({ setAdminPrivilege, userPrivilege, ...props }) {
  const classes = useStyles();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <img src={Logo} style={{ width: '150px' }} alt="" />
      </IconButton>

      {labels.map(l => (
        <Typography variant="h5" className={classes.title} key={`nav-${l.label}`}>
          <Button color="inherit" component={RouterLink} to={l.path}>
            {' '}
            {l.label.toUpperCase()}
          </Button>
        </Typography>
      ))}
      <Typography variant="subtitle1" className={classes.title}>
        {' '}
        v{version}
      </Typography>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const [openAuthForm, setOpen] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const handleSubmission = React.useCallback(async values => {
    setSpinner(true);
    // const user = await signIn(values);
    // if (user && user.attributes['custom:privilege'] === 'admin') {
    //   setAdminPrivilege();
    // }
    setSpinner(false);
    setOpen(false);
  }, []);
  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav" color={userPrivilege === 'admin' ? 'secondary' : 'primary'}>
        <Toolbar sx={{ p: `0 !important` }}>
          <IconButton color="inherit" onClick={() => setOpen(true)}>
            <img src={Logo} alt="" className="_logo" />
          </IconButton>
          <IconButton
            aria-label="open menu"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, ml: 'auto' }}
          >
            <Menu />
          </IconButton>
          <Box className="nav_widescreencontent">
            {labels.map(l => (
              <Typography variant="h5" className={classes.title} key={`nav-${l.label}`}>
                <Button color="inherit" component={RouterLink} to={l.path}>
                  {' '}
                  {l.label.toUpperCase()}
                </Button>
              </Typography>
            ))}
          </Box>
        </Toolbar>
        <AuthForm onSubmit={handleSubmission} open={openAuthForm} onClose={() => setOpen(false)} />
        {spinner && <CircularIndeterminate />}
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: '100%' }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
