import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));
export default function AuthForm({
  onSubmit,
  disableBackdropClick,
  open,
  onClose,
}) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    username: "",
    password: "",
    showPassword: false,
  });
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleSubmission = React.useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);
  return (
    <div>
      <Dialog
        open={open}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableBackdropClick}
        onClose={() => (!disableBackdropClick ? onClose() : null)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Sign In Your Account"}
        </DialogTitle>
        <DialogContent>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <InputLabel>Account</InputLabel>
            <Input
              type={"text"}
              value={values.username}
              onChange={handleChange("username")}
            />
          </FormControl>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
