import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import config from "./aws-exports";
const AuthConfig = {
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_d7EhrO30w",
    userPoolWebClientId: `7nat5r52jusmbumajdv3hjt17j`,
  },
};
Amplify.configure({ ...config, ...AuthConfig });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
