import React from 'react';
import clsx from 'clsx';
import { Star, Add, KeyboardReturn, Edit, Delete } from '@material-ui/icons';
import { yellow, red, grey } from '@material-ui/core/colors';
import { Button, Collapse, Grid, Paper, IconButton } from '@material-ui/core';
import TextEditor, { ProtoEditor, PDFLoader } from './TextEditor';
import { CircularIndeterminate } from './Spinner';
import 'draft-js/dist/Draft.css';
import { API, graphqlOperation } from 'aws-amplify';
import { createArticleTable, updateArticleTable } from '../graphql/mutations';
import {
  onCreateArticleTable,
  onDeleteArticleTable,
  onUpdateArticleTable,
} from '../graphql/subscriptions';
import { autoFindIMG, autoFindDesc } from '../utils';
import useStyles from '../utils/styles';
import SECTIONS, { SECTION_MAP } from './section';
import { Typography, Dialog, AppBar, Toolbar, Slide, Box, CssBaseline } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const insertIntoArray = (arr, idx, value) => [...arr.slice(0, idx), value, ...arr.slice(idx + 1)];
const bufferStr = (file, regex) => file.replace(regex, '');
const MODE = { CREATE: 'create', EDIT: 'edit' };

const titleSX = {
  fontSize: { md: '3rem', xs: '1rem' },
  fontFamily: 'monospace',
  borderBottom: '1px solid #f2f2f2',
  display: `flex`,
  alignItems: `center`,
};
export default function Content({
  section,
  userPrivilege,
  sectionContent = [],
  setContent,
  retrieveContent,
}) {
  const classes = useStyles();
  const [mode, setMode] = React.useState('');
  const [spinner, setSpinner] = React.useState(false);
  const content = React.useMemo(() => {
    return sectionContent
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .map(c => ({
        ...c,
        content: typeof c.content === 'string' ? JSON.parse(c.content) : c.content,
      }));
  }, [sectionContent]);

  const [currentExpansion, setExpansion] = React.useState(-1);
  const [openEditor, setOpenEditor] = React.useState(false);
  React.useEffect(() => {
    setOpenEditor(false);
  }, [section]);

  const handleExpansion = idx => {
    setExpansion(idx === currentExpansion ? -1 : idx);
  };
  const handleStarring = async idx => {};
  const handleEdit = idx => {
    setExpansion(idx);
    setOpenEditor(true);
    setMode(MODE.EDIT);
  };
  const handleAddContent = () => {
    setOpenEditor(!openEditor);
    setExpansion(-1);
    setMode(MODE.CREATE);
  };
  const handleDeletion = async idx => {};
  const saveEditorContent = React.useCallback(
    async ({ editorState, title, imageMap, coverImage, importFile, id }) => {},
    [],
  );
  const [currentSection, setCS] = React.useState(null);

  const computedAllContent = React.useMemo(() => {
    const findOne = title => content.find(it => it.title.match(new RegExp(title, 'gi')));
    const validSection = Object.keys(SECTION_MAP)
      .map((section, idx) => findOne(section))
      .filter(it => it);
    const validIds = [...Object.values(SECTION_MAP).flat(), ...validSection.map(it => it.id)];
    return content
      .sort((a, b) => (a.starred ? 0 : 1) - (b.starred ? 0 : 1))
      .filter(it => {
        return currentSection === 'Unassigned Section' ? !validIds.includes(it.id) : true;
      });
  }, [content, currentSection]);
  const findExistingOne = React.useCallback(
    title => {
      return computedAllContent.find(it => it.title.match(new RegExp(title, 'gi')));
    },
    [computedAllContent],
  );

  const currentContent = React.useMemo(() => {
    return content[currentExpansion] || {};
  }, [content, currentExpansion]);

  const isAdmin = React.useMemo(() => userPrivilege === 'admin', [userPrivilege]);

  const productNum = React.useMemo(() => {
    return SECTIONS.reduce(
      (prev, section) => ({
        ...prev,
        [section]: computedAllContent.filter(it => SECTION_MAP[section].includes(it.id)).length,
      }),
      {},
    );
  }, [computedAllContent]);
  const handleClose = () => {
    setOpenEditor(false);
    setCS(null);
  };
  const handleItemClose = () => {
    setExpansion(-1);
    setOpenEditor(false);
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={Boolean(currentSection)}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box>
          <CssBaseline />
          <AppBar className={classes.appBar} component={'nav'}>
            <Toolbar>
              <Typography variant="h3" sx={titleSX}>
                {currentSection}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                aria-label="close"
                style={{ marginLeft: 'auto' }}
              >
                Return To Previous View
              </Button>
            </Toolbar>
          </AppBar>
          {currentSection && (
            <Box component="main">
              <Toolbar />
              <Grid container item xs={12}>
                {SECTION_MAP[currentSection].map(sub => (
                  <>
                    {computedAllContent.map((li, idx) => (
                      <ListCard
                        key={`content-${idx}`}
                        {...{
                          li,
                          idx,
                          handleExpansion,
                          classes,
                          currentExpansion,
                          handleStarring,
                          handleEdit,
                          isAdmin,
                          handleDeletion,
                          hide: currentSection === 'Unassigned Section' ? false : li.id !== sub,
                        }}
                      />
                    ))}
                  </>
                ))}
                {content.length > 0 && (
                  <Grid xs={12} md={6} item style={{ margin: 'auto' }}>
                    <Collapse in={currentExpansion > -1} timeout="auto" unmountOnExit>
                      <Typography
                        variant="h2"
                        className={classes.displayedTitle}
                        sx={{ fontSize: { xs: '1.5rem', md: '3rem' } }}
                      >
                        {currentContent.title}
                      </Typography>
                      <hr />
                      <Paper className={clsx(classes.paper, classes.subpaper)}>
                        {currentContent.pdfContent && (
                          <PDFLoader file={currentContent.pdfContent} />
                        )}

                        <ProtoEditor editorState={currentContent.content} readOnly />
                      </Paper>
                    </Collapse>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </Dialog>
      <Dialog
        fullScreen
        open={currentExpansion > -1}
        onClose={handleItemClose}
        TransitionComponent={Transition}
      >
        <Box>
          <CssBaseline />
          <AppBar className={classes.appBar} component={'nav'}>
            <Toolbar>
              <Typography variant="h3" sx={titleSX}>
                {currentContent.title}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleItemClose}
                aria-label="close"
                style={{ marginLeft: 'auto' }}
              >
                Return To Previous View
              </Button>
            </Toolbar>
          </AppBar>
          {currentExpansion > -1 && (
            <Box component="main">
              <Toolbar />
              <Paper className={clsx(classes.paper, classes.subpaper)}>
                {!openEditor && (
                  <>
                    {currentContent.pdfContent && <PDFLoader file={currentContent.pdfContent} />}
                    <ProtoEditor editorState={currentContent.content} readOnly />
                  </>
                )}

                {openEditor && (
                  <TextEditor
                    saveEditorContent={saveEditorContent}
                    currentContent={currentContent}
                    cover={autoFindIMG(currentContent)}
                    pdf={currentContent.pdfContent}
                  />
                )}
              </Paper>
            </Box>
          )}
        </Box>
      </Dialog>
      <div className={classes.content_root} style={{ opacity: spinner ? '.6' : '1' }}>
        <img
          src="./moulding-machine-files/3761685329026_.pic.jpg"
          style={{ margin: 'auto', width: '50%' }}
          alt=""
        />
        <div className={classes.subroot}>
          {!openEditor && (
            <React.Fragment>
              {[...SECTIONS, isAdmin ? 'Unassigned Section' : '']
                .filter(it => it)
                .map((section, idx) => {
                  const targetFromDB = findExistingOne(section);
                  const coverLink = autoFindIMG(targetFromDB);

                  return (
                    <>
                      <Grid
                        container
                        onClick={event => {
                          event.preventDefault();
                          event.stopPropagation();
                          setCS(s => (s === section ? null : section));
                          setExpansion(-1);
                        }}
                        className={classes.listPaper}
                      >
                        <Grid container item xs={2} className="cover">
                          {coverLink.match(/https:\/\/.*.pdf$/gi) ? (
                            <PDFLoader file={coverLink} />
                          ) : (
                            <div
                              style={{
                                backgroundImage: `url("${coverLink}")`,
                              }}
                              className={classes.cardImg}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          container
                          direction="column"
                          spacing={12}
                          className={classes.inheritHeightContainer}
                          style={{ gridGap: '10px', margin: 'auto' }}
                        >
                          <Grid container item>
                            <Typography variant="h3" sx={titleSX} className="_title">
                              {section}
                            </Typography>
                            {section !== 'Unassigned Section' && (
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: { xs: '.5rem', md: '1.5rem' },
                                  flex: `1 1 100%`,
                                  fontStyle: `italic`,
                                  color: `red`,
                                }}
                              >
                                {productNum[section] || 0} Products
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            </React.Fragment>
          )}
        </div>
        {isAdmin && (
          <div
            style={{
              justifyContent: `flex-end`,
              display: `flex`,
              margin: `16px`,
            }}
          >
            <Button variant="contained" color="primary" onClick={handleAddContent}>
              {openEditor ? <KeyboardReturn /> : <Add />}
              <Typography variant="h6">
                {openEditor ? 'Return To List View' : 'Create New Title'}
              </Typography>
            </Button>
          </div>
        )}
      </div>
      {spinner && <CircularIndeterminate />}
    </React.Fragment>
  );
}

function ListCard({
  li,
  idx,
  currentExpansion,
  handleExpansion,
  handleStarring,
  handleEdit,
  classes,
  isAdmin,
  handleDeletion,
  hide,
}) {
  if ((currentExpansion !== idx && currentExpansion > -1) || hide) return null;
  const desc = autoFindDesc(li.content.blocks);
  return (
    <Grid
      container
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        handleExpansion(idx);
      }}
      className={classes.listPaper}
    >
      <Grid container item xs={2} className="cover">
        <div
          style={{
            backgroundImage: `url("${autoFindIMG(li)}")`,
          }}
          className={classes.cardImg}
        />
      </Grid>
      <Grid
        item
        xs={10}
        container
        direction="column"
        spacing={12}
        className={classes.inheritHeightContainer}
        style={{ gridGap: '10px', margin: 'auto' }}
      >
        <Grid container item className={classes.listContentPadding} justify="space-between">
          <Typography variant="h3" component="span" sx={titleSX}>
            {li.title}
          </Typography>
        </Grid>

        {desc && (
          <Grid item className={classes.listContentPadding}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              className={classes.thumbDesc}
            >
              {desc}
            </Typography>
          </Grid>
        )}

        {isAdmin && (
          <Grid item container className={classes.listContentPadding}>
            <Button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleEdit(idx);
              }}
              style={{ color: red[300], padding: '0px' }}
            >
              Edit
              <Edit />
            </Button>
            <IconButton
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                handleStarring(idx);
              }}
            >
              <Star style={{ color: li.starred ? yellow[300] : grey[300] }} />
            </IconButton>
            <IconButton
              onClick={async event => {
                event.preventDefault();
                event.stopPropagation();
                handleDeletion(idx);
              }}
            >
              <Delete style={{ color: red[500] }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
