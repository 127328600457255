import React from "react";
import {
  AtomicBlockUtils,
  // Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Button, Paper, TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red, orange, green, blue } from '@material-ui/core/colors';
import {
  ImageOutlined,
  List,
  FormatListNumbered,
  Code,
  FormatQuote,
  Wallpaper,
  Save,
  Delete,
} from '@material-ui/icons';
import { Page, Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import '@draft-js-plugins/image/lib/plugin.css';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';

import createImagePlugin from '@draft-js-plugins/image';

import createAlignmentPlugin from '@draft-js-plugins/alignment';

import createFocusPlugin from '@draft-js-plugins/focus';

import createResizeablePlugin from '@draft-js-plugins/resizeable';

// import createBlockDndPlugin from "@draft-js-plugins/drag-n-drop";

// import createDragNDropUploadPlugin from "@draft-js-plugins/drag-n-drop-upload";

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
// const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  // blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });

// const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
//   handleUpload: () => {},
//   addImage: imagePlugin.addImage,
// });

const plugins = [
  // dragNDropFileUploadPlugin,
  // blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
];

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "'Georgia', serif",
    padding: theme.spacing(2),
  },
  urlInputContainer: {
    margin: theme.spacing(2),
    alignItems: "center",
    display: "flex",
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
  },
  editor: {
    border: "1px solid #ccc",
    cursor: "text",
    minHeight: 400,
    padding: theme.spacing(2),
  },
  button: {
    textAlign: "center",
    border: "1px grey solid",
  },
  editorWrapper: {
    border: "2px dashed",
    borderColor: red[200],
    padding: theme.spacing(0),
    background: "fefefeff",
    minHeight: "400px",
    cursor: "text",
  },
  buttonGroup: {
    display: "flex",
    flexFlow: "row wrap",
  },
}));
export default function TextEditor({ saveEditorContent, currentContent, cover, pdf }) {
  const classes = useStyles();
  const fileInputRef = React.useRef(null);
  const [currentFileType, setFileType] = React.useState('');

  const [importFile, setImportFile] = React.useState(null);
  const [coverImage, setCoverImage] = React.useState(null);
  const [imageMap, setImageMap] = React.useState([]);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [state, setState] = React.useState({
    showURLInput: false,
    url: '',
    urlType: '',
  });
  const [title, setTitle] = React.useState('');
  React.useEffect(() => {
    setEditorState(
      EditorState.createWithContent(convertFromRaw(currentContent.content || DEFAULT_STATE)),
    );
    setTitle(currentContent.title);
    if (cover) setCoverImage({ file: cover });
  }, [currentContent, cover]);
  const onChangeTitle = e => setTitle(e.target.value);
  const editor = React.useRef(null);
  const url = React.useRef(null);

  const focus = () => editor.current.focus();
  const onChange = editorStateToUpdate => {
    if (
      Object.keys(convertToRaw(editorStateToUpdate.getCurrentContent()).entityMap).length >
      Object.keys(convertToRaw(editorState.getCurrentContent()).entityMap).length
    ) {
      // console.log(
      //   convertToRaw(editorStateToUpdate.getCurrentContent()).entityMap[
      //     Object.keys(
      //       convertToRaw(editorStateToUpdate.getCurrentContent()).entityMap
      //     ).length - 1
      //   ]
      // );
    }
    return setEditorState(editorStateToUpdate);
    // setImageMap([...imageMap, { file, fileName }]);
  };
  const confirmMedia = ({ urlValue, urlType, file, fileName }) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(urlType, 'IMMUTABLE', {
      src: urlValue,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    setImageMap([...imageMap, { file, fileName }]);
    setState({
      ...state,
      // The third parameter here is a space string, not an empty string
      // If you set an empty string, you will get an error: Unknown DraftEntity key: null
      showURLInput: false,
      urlValue: '',
    });
    setTimeout(() => editor.current.focus(), 0);
  };
  const _promptForMedia = type => {
    setState({
      ...state,
      showURLInput: !state.showURLInput,
      urlValue: '',
      urlType: type,
    });
    setTimeout(() => url.current.focus(), 0);
  };

  const addAudio = () => {
    _promptForMedia('audio');
  };

  const fileUploadClick = type => () => {
    setFileType(type);
    fileInputRef.current.click();
  };
  const uploadFile = e => {
    if (!e.target.files[0]) return null;
    switch (currentFileType) {
      case 'image':
        addFile(e);
        break;
      case 'cover':
        addCover(e);
        break;
      case 'import':
        importPDF(e);
        break;
      default:
        break;
    }
  };
  const addVideo = () => {
    _promptForMedia('video');
  };
  const importPDF = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (event) {
      setImportFile({ file: event.target.result, fileName: file.name });
    };
    reader.readAsDataURL(file);
  };
  const addFile = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (event) {
      confirmMedia({
        urlValue: reader.result,
        urlType: 'image',
        file: event.target.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };
  const addCover = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (event) {
      setCoverImage({ file: event.target.result, fileName: file.name });
    };
    reader.readAsDataURL(file);
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };
  const _onStyleClick = style => () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };
  const _onBlockClick = blockType => () => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  /*Save */
  const saveContent = React.useCallback(() => {
    setState({
      ...state,
      showURLInput: false,
    });
    saveEditorContent({
      editorState: convertToRaw(editorState.getCurrentContent()),
      title,
      imageMap,
      coverImage,
      importFile,
      id: currentContent.id,
    });
  }, [
    editorState,
    saveEditorContent,
    state,
    title,
    imageMap,
    coverImage,
    importFile,
    currentContent,
  ]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          onChange={onChangeTitle}
          value={title}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={fileUploadClick('cover')}
          style={{ background: green[300], color: 'white' }}
          endIcon={<Wallpaper />}
        >
          Add Cover
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={fileUploadClick('image')}
          style={{ background: orange[300], color: 'white' }}
          endIcon={<ImageOutlined />}
        >
          Add Image
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={fileUploadClick('import')}
          style={{ background: red[300], color: 'white' }}
          endIcon={<Wallpaper />}
        >
          Import PDF
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/png, image/jpeg, application/pdf "
          onChange={uploadFile}
          style={{ visibility: 'hidden' }}
        />
        <p>
          * One PDF Content(once you use PDF Content and create it, it will be uneditable at current
          version) & Cover per Article
        </p>
      </Grid>
      {coverImage && coverImage.file && (
        <Grid item xs={12} container justify="center">
          <img src={coverImage.file} alt="cover" className="_coverIFRAME" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.root}>
          {pdf && (
            <iframe
              src={pdf}
              alt="cover"
              style={{
                width: '100%',
                height: '300px',
              }}
            />
          )}
          {!pdf && (
            <>
              <div className={classes.buttonGroup}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={_onStyleClick('BOLD')}
                  color="primary"
                  style={{ fontWeight: 'bolder' }}
                >
                  B
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={_onStyleClick('ITALIC')}
                  color="secondary"
                  style={{ fontStyle: 'italic' }}
                >
                  I
                </Button>
                <Button
                  className={classes.button}
                  onClick={_onBlockClick('paragraph')}
                  color="secondary"
                  style={{ fontWeight: 'bolder', fontStyle: 'italic' }}
                >
                  P
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={_onBlockClick('unordered-list-item')}
                  style={{ background: blue[300], color: 'white' }}
                >
                  <List />
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={_onBlockClick('ordered-list-item')}
                  style={{ background: blue[300], color: 'white' }}
                >
                  <FormatListNumbered />
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={_onBlockClick('blockquote')}
                  style={{ background: blue[300], color: 'white' }}
                >
                  <FormatQuote />
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={_onBlockClick('code-block')}
                  style={{ background: blue[300], color: 'white' }}
                >
                  <Code />
                </Button>
                {[
                  ['H1', 'one'],
                  ['H2', 'two'],
                  ['H3', 'three'],
                  ['H4', 'four'],
                  ['H5', 'five'],
                  ['H6', 'six'],
                ].map(bt => (
                  <Button
                    className={classes.button}
                    onClick={_onBlockClick(`header-${bt[1]}`)}
                    key={bt[0]}
                    style={{ background: blue[300], color: 'white' }}
                  >
                    {bt[0]}
                  </Button>
                ))}

                {/*    <Button
              className={classes.button}
              variant="contained"
              onClick={addAudio}
              style={{ background: orange[500], color: "white" }}
              endIcon={<Audiotrack />}
            >
              Add Audio
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              onClick={addVideo}
              style={{ background: orange[200], color: "white" }}
              endIcon={<VideoCall />}
            >
              Add Video
            </Button> */}
              </div>
              <Paper className={classes.editorWrapper} onClick={focus}>
                {importFile && (
                  <React.Fragment>
                    <Button
                      className={classes.button}
                      variant="contained"
                      onClick={() => {
                        setImportFile(null);
                      }}
                      style={{ background: red[500], color: 'white' }}
                    >
                      Delete PDF Content <Delete />
                    </Button>
                    <PDFLoader file={importFile.file} />
                  </React.Fragment>
                )}
                <ProtoEditor
                  editorState={editorState}
                  handleKeyCommand={handleKeyCommand}
                  onChange={onChange}
                  placeholder="Edit Your Content..."
                  ref={editor}
                />
              </Paper>
              <Grid
                container
                xs={12}
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ marginTop: '10px' }}
              >
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={saveContent}
                  style={{ background: green[300], color: 'white' }}
                >
                  Save Content <Save />
                </Button>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

function mediaBlockRenderer(block) {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }

  return null;
}

const mediaStyle = {
  width: "100%",
  // Fix an issue with Firefox rendering video controls
  // with 'pre-wrap' white-space
  whiteSpace: "initial",
};
const Audio = props => {
  return <audio controls src={props.src} style={mediaStyle} />;
};

const Image = props => {
  return <img src={props.src} style={mediaStyle} alt="" />;
};

const Video = props => {
  return <video controls src={props.src} style={mediaStyle} />;
};

const Media = props => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  const type = entity.getType();

  let media;
  if (type === "audio") {
    media = <Audio src={src} />;
  } else if (type === "image") {
    media = <Image src={src} />;
  } else if (type === "video") {
    media = <Video src={src} />;
  }

  return media;
};

const DEFAULT_STATE = {
  entityMap: {},
  blocks: [],
};
export const ProtoEditor = React.forwardRef(
  ({ editorState, handleKeyCommand, onChange, placeholder, readOnly }, ref) => {
    if (readOnly) {
      return (
        <Editor
          blockRendererFn={mediaBlockRenderer}
          editorState={EditorState.createWithContent(
            convertFromRaw(editorState || DEFAULT_STATE)
          )}
          readOnly
          plugins={plugins}
        />
      );
    }
    return (
      <Editor
        blockRendererFn={mediaBlockRenderer}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        plugins={plugins}
      />
    );
  }
);

export const PDFLoader = ({ file }) => {
  const [numPages, setNumPages] = React.useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      {Array(numPages)
        .fill(0)
        .map((page, idx) => (
          <Page pageNumber={idx + 1} key={`pdf-page-${idx}`} />
        ))}
    </Document>
  );
};
